<template>
	<!-- 支付弹窗 -->
	<div>
		<a-modal v-model="prepayModal" title="立即购买" :footer="null" :maskClosable="false" :centered="true">
			<div class="prepay_modal">
				<div class="line">
					<div class="label">购买套餐：</div>
					<div class="value">{{ prepay.title }}</div>
				</div>
				<div class="line">
					<div class="label">支付金额：</div>
					<div class="value">{{ prepay.price }}</div>
				</div>
				<div class="line">
					<div class="label">支付方式：</div>
					<div class="value">
						<div class="way" :class="{ active: payment === 'wechat' }" @click="payment = 'wechat'">
							<a-icon type="wechat" theme="filled" :style="{ fontSize: '23px', color: '#09BB07' }" />
							<span>微信支付</span>
						</div>
						<div class="way" :class="{ active: payment === 'alipay' }" @click="payment = 'alipay'">
							<a-icon type="alipay-circle" theme="filled" :style="{ fontSize: '20px', color: '#019FE8' }" />
							<span>支付宝支付</span>
						</div>
					</div>
				</div>
				<div class="button">
					<a-button type="primary" style="width: 150px; background: #23b571; border-color: #23b571" @click="createOrder">立即支付</a-button>
				</div>
			</div>
		</a-modal>

		<a-modal v-model="payModal" :title="payment | paymentText" :footer="null" :maskClosable="false" :centered="true">
			<div class="qrcode_modal">
				<div class="qrcode">
					<div ref="qrCodeUrl"></div>
				</div>
				<p class="text-gray-7 mt-20 mb-0">请使用手机{{ payment | paymentText }}</p>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import QRCode from "qrcodejs2"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		props: {
			showModal: {
				type: Boolean,
				default: false
			},
			prepayData: {
				type: Object,
				default: () => {}
			},
			payType: {
				type: String,
				default: ""
			}
		},
		data() {
			return {
				payModal: false,
				prepayModal: false,
				payment: "wechat",
				pay: {
					order: "",
					qrcode: "",
					timer: null
				},
				prepay: {
					id: "",
					title: "",
					price: ""
				}
			}
		},
		watch: {
			prepayModal(newValue, oldValue) {
				if (!newValue) {
					this.$emit("hideModal", false)
					this.payModal = false
				}
			},
			payModal(newValue, oldValue) {
				if (!newValue) {
					clearInterval(this.pay.timer)
					this.pay = {
						order: "",
						qrcode: "",
						timer: null
					}
				}
			},
			showModal(newValue, oldValue) {
				this.prepayModal = newValue
			},
			prepayData: {
				deep: true,
				handler(newValue, oldValue) {
					this.prepay = newValue
				}
			}
		},
		filters: {
			paymentText(value) {
				if (value === "wechat") {
					return "微信扫一扫支付"
				} else if (value === "alipay") {
					return "支付宝扫一扫支付"
				}
			}
		},
		created() {},
		mounted() {},
		methods: {
			...mapActions("user", ["getUserInfo"]),
			// 查询支付状态
			queryPayStatus() {
				this.$http("recharge.queryPayStatus", {
					payment: this.payment,
					orderid: this.pay.order
				})
					.then(res => {
						if (res.code === 1) {
							if (res.data && res.data.status && res.data.status === "SUCCESS") {
								this.getUserInfo()
								this.$message.success("支付成功！")
								this.prepayModal = false
							}
						} else {
							this.$message.error("支付失败！")
							this.payModal = false
						}
					})
					.catch(err => {
						console.log(err)
					})
			},
			// 生成二维码
			creatQrCode() {
				// 重复创建二维码会，之前的二维码还在，新创建的会被接在下面
				// 此行代码是为了解决以上问题 或者 刷新二维码的时候生成多个二维码的问题
				if (this.$refs.qrCodeUrl) this.$refs.qrCodeUrl.innerHTML = ""
				new QRCode(this.$refs.qrCodeUrl, {
					text: this.pay.qrcode, // 需要转换为二维码的内容
					width: 200,
					height: 200,
					correctLevel: QRCode.CorrectLevel.H
				})
				this.pay.timer = setInterval(() => {
					this.queryPayStatus()
				}, 1000)
			},
			// 生成订单
			createOrder() {
				if (!this.payType) {
					this.$message.error("购买失败！")
					return
				}

				if (this.payType === "integral") {
					console.log("积分充值")
					const obj = {
						usable_id: this.prepay.id,
						total_fee: this.prepay.price,
						platform: "Web"
					}
					this.$http("recharge.createIntegralOrder", obj)
						.then(res => {
							if (res.code === 1) {
								this.pay.order = res.data.order_sn
								this.postPay(res.data.order_sn)
							}
						})
						.catch(err => {
							console.log(err, "err")
						})
				} else if (this.payType === "member") {
					console.log("会员充值")
					const obj = {
						vip_id: this.prepay.id,
						total_fee: this.prepay.price,
						platform: "Web"
					}
					this.$http("recharge.createMemberOrder", obj)
						.then(res => {
							if (res.code === 1) {
								this.pay.order = res.data.order_sn
								this.postPay(res.data.order_sn)
							}
						})
						.catch(err => {
							console.log(err, "err")
						})
				} else if (this.payType === "dealer") {
					console.log("经销商充值")
					const obj = {
						reseller_id: this.prepay.id,
						total_fee: this.prepay.price,
						platform: "Web"
					}
					this.$http("recharge.createDealerOrder", obj)
						.then(res => {
							if (res.code === 1) {
								this.pay.order = res.data.order_sn
								this.postPay(res.data.order_sn)
							}
						})
						.catch(err => {
							console.log(err, "err")
						})
				}
			},
			// 支付
			postPay(sn) {
				const obj = {
					order_sn: sn,
					payment: this.payment,
					platform: "Web"
				}

				this.$http("recharge.pay", obj)
					.then(res => {
						if (res.code === 1) {
							this.pay.qrcode = res.data.pay_data
							if(this.payment=='wechat'){
							this.payModal=true
							const timer = setTimeout(() => {
								if(this.payment=='wechat'){
									this.creatQrCode()
								}
								clearTimeout(timer)
							}, 1000)
							}else{
							// 支付宝
							 document.write(this.pay.qrcode)
							}
						}
					})
					.catch(err => {
						console.log(err, "err")
					})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.prepay_modal {
		padding: 20px 0;

		.line {
			display: flex;
			margin-bottom: 30px;
			.label {
			}
			.value {
				display: flex;
				align-items: center;

				.way {
					border: 1px solid #ddd;
					padding: 10px 20px;
					border-radius: 10px;
					display: flex;
					align-items: center;
					margin-right: 15px;
					cursor: pointer;

					&.active {
						border-color: #23b571;
					}

					span {
						margin-left: 10px;
					}
				}
			}
		}

		.button {
			margin-top: 50px;
			display: flex;
			justify-content: center;
		}
	}
	.qrcode_modal {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 80px 0;

		.qrcode {
			width: 200px;
			height: 200px;
		}
	}
</style>
