<!-- 充值中心 -->

<template>
	<div>
		<div style="height: 70px;"></div>
		<a-card :bordered="false" class="card-profile-head" style="width: 100%;margin-left: 0;" :bodyStyle="{ padding: 0 }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="col-info">
						<a-avatar :size="74" shape="square" :src="userInfo.avatar" />
						<div class="avatar-info">
							<h4 class="font-semibold m-0">{{ userInfo.nickname }}</h4>
							<div v-if="tabsId != 3">
								<p >积分余额:<span style="color:#aa55ff;font-size: 18px;"><strong>{{ userInfo.usable || 0 }}</strong></span></p>
								<p v-if="userInfo.is_vip === 1">VIP到期时间：<span style="color:rgb(255, 170, 0);font-size: 18px;">{{ userInfo.vip_expiretime_text }}</span></p>
								<p v-else>您还没有开通会员</p>
							</div>
							<div v-if="tabsId === 3">
								<div v-if="userInfo.reseller">
									<p>{{ userInfo.reseller.reseller_json.name }}</p>
									<p>到期时间：{{ userInfo.reseller.expiretime_text }}</p>
								</div>
								<div v-else>
									<p>您还没有开通经销商</p>
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
						<a-tooltip placement="bottom" style="margin-right: 10px;border: 0;" >
							<template slot="title">
								<span>卡密兑换</span>
							</template>
							<a-button type="danger"  icon="gift" @click="cdkey.show = true"><span>卡密兑换</span></a-button>
						</a-tooltip>
						<a-tooltip placement="bottom" style="margin-right: 10px;border: 0;" >
							<template slot="title">
								<span>复制邀请链接</span>
							</template>
							<a-button type="primary" icon="usergroup-add" @click="copyShareLink"><span>邀请好友</span></a-button>
						</a-tooltip>
					</a-col>
				</a-row>
			</template>
		</a-card>
        
		<div v-if="tabsId === 1">
			积分套餐：
			<RechargeIntegral></RechargeIntegral>
			会员套餐：
			<RechargeMember></RechargeMember>
		</div>
		<div v-if="tabsId === 2">
			<RechargeMember></RechargeMember>
		</div>
		<div v-if="tabsId === 3">
			<RechargeDealer></RechargeDealer>
		</div>
		<a-modal v-model="cdkey.show" title="卡密兑换" :centered="true" :maskClosable="false" cancelText="取消" okText="确定" :confirmLoading="cdkey.loading" @ok="cdkeyConfirm">
			<div class="cdkey_modal">
				<a-input v-model="cdkey.value" :allowClear="true" placeholder="请输入兑换码">
					<a-icon slot="prefix" type="meh" />
				</a-input>
			</div>
		</a-modal>
	</div>
	
</template>

<script>
	import RechargeIntegral from "../components/Custom/RechargeIntegral.vue"
	import RechargeMember from "../components/Custom/RechargeMember.vue"
	import RechargeDealer from "../components/Custom/RechargeDealer.vue"
	
	import AccountInfo from "../components/Custom/AccountInfo.vue"
	import BaseInfo from "../components/Custom/BaseInfo.vue"
	import InviteInfo from "../components/Custom/InviteInfo.vue"
	import PlaceTable from "../components/Custom/PlaceTable.vue"
	import WriteTable from "../components/Custom/WriteTable.vue"
	import ChatCollect from "../components/Custom/ChatCollect.vue"
	
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		components: {
			RechargeIntegral,
			RechargeMember,
			RechargeDealer,
			AccountInfo,
			BaseInfo,
			InviteInfo,
			PlaceTable,
			WriteTable,
			ChatCollect
		},
		data() {
			return {
				tabsId: 1,
				tabsList: [
					{ id: 1, title: "积分充值" },
					{ id: 2, title: "开通会员" },
					{ id: 3, title: "开通经销商" }
				],
				cdkey: {
					show: false,
					loading: false,
					value: ""
				}
			}
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"]),
			...mapState("app", ["sign"])
		},
		watch: {
        '$route': function(newVal, oldVal) {
            const tabsId = this.$route.query.tabsId;
            if(tabsId == undefined || tabsId == ''){
                this.tabsId = 1;
            }else{
                this.tabsId = parseInt(tabsId);
            }
            console.log('Route changed from ' + oldVal + ' to ' + newVal);

        }
    },
		created() {
			this.getUserInfo();
			// this.getStatisticInfo()
		},
		methods: {
			...mapActions("user", ["getUserInfo", "getStatisticInfo"]),
			// 兑换
			cdkeyConfirm() {
				if (!this.cdkey.value) return
				this.cdkey.loading = true
				this.$http("user.cdkey", {
					crypto: this.cdkey.value,
					platform: "Web"
				})
					.then(res => {
						res.code === 1 && this.$message.success(res.msg)
						this.cdkey.value = ""
						this.cdkey.loading = false
						this.cdkey.show = false
					})
					.catch(err => {
						this.$message.error("兑换失败")
						this.cdkey.value = ""
						this.cdkey.loading = false
						this.cdkey.show = false
					})
			},
			// 复制
			async copyShareLink() {
				const spm = this.userInfo.id + ".1.0.4.3"
				const link = window.location.origin + window.location.pathname + `?${this.sign}#/?share=${spm}`
				try {
					await navigator.clipboard.writeText(link)
					this.$message.success("已复制到剪切板")
				} catch (err) {
					this.$message.error("复制失败")
				}
			}
		},
      mounted() {

          // this.$router.push("/recharge/tabsId?id=" + 1)
          const tabsId = this.$route.query.tabsId;
          if(tabsId == undefined || tabsId == ''){
              this.tabsId = 1;
          }else{
              this.tabsId = parseInt(tabsId);
          }
          // if (tabsId !=''){
          //     this.tabsId = tabsId;
          // }
		  

      },


	}
</script>

<style lang="scss">
	.cdkey_modal {
		::v-deep .ant-input {
			letter-spacing: 1px;
	
			&::placeholder {
				font-weight: normal;
			}
		}
	}
</style>
